import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { id: "logo-space" }
const _hoisted_3 = {
  id: "logo",
  viewBox: "120.055 86.526 260.074 326.59",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:bx": "https://boxy-svg.com"
}
const _hoisted_4 = {
  ref: "eyeball",
  id: "eyeball",
  cx: "250",
  cy: "283.379",
  r: "30"
}
const _hoisted_5 = { id: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bx58grid = _resolveComponent("bx:grid")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ThemeToggle = _resolveComponent("ThemeToggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("svg", _hoisted_3, [
        _createElementVNode("defs", null, [
          _createVNode(_component_bx58grid, {
            x: "120.055",
            y: "86.526",
            width: "260.074",
            height: "326.59"
          })
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("path", {
          d: "M 790.4 423.2 m -129.003 0 a 129.003 129.003 0 1 0 258.006 0 a 129.003 129.003 0 1 0 -258.006 0 Z M 790.4 423.2 m -64.501 0 a 64.501 64.501 0 0 1 129.002 0 a 64.501 64.501 0 0 1 -129.002 0 Z",
          style: {},
          transform: "matrix(1, 0.000079, -0.000079, 1, -540.366392, -139.864531)",
          "bx:shape": "ring 790.4 423.2 64.501 64.501 129.003 129.003 1@68f4b763"
        }, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("rect", {
          x: "120.987",
          y: "87.5",
          width: "64.643",
          height: "325",
          rx: "32.3",
          ry: "32.3"
        }, null, -1)),
        _cache[2] || (_cache[2] = _createElementVNode("rect", {
          x: "314.37",
          y: "87.5",
          width: "64.643",
          height: "325",
          rx: "32.3",
          ry: "32.3"
        }, null, -1)),
        _createElementVNode("circle", _hoisted_4, null, 512)
      ])),
      _cache[3] || (_cache[3] = _createElementVNode("h1", null, "lost design", -1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("nav", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Home")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/portfolio" }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Portfolio")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/about" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("About")
          ])),
          _: 1
        })
      ]),
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_ThemeToggle)
  ]))
}