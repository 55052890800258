import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "home" }
const _hoisted_2 = { class: "cta-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Welcome to Lost Design", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, "Creative web development & design solutions", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/portfolio",
        class: "btn primary"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("View Portfolio")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/about",
        class: "btn secondary"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("About Me")
        ])),
        _: 1
      })
    ])
  ]))
}